<template>
  <div class="mainboxs">
    <div class="mainbox">
      <div class="news_til">
        <div class="news_til_left">
          <img src="~@/assets/img/TechnicalCollege/29.png" alt="">高级研修班学校推荐
        </div>
        <div @click="goNext('/gxGather/graduate/academy')" class="news_til_right">院校库 >>
        </div>
      </div>
      <div class="academy_list">
        <div @click="goNext(`/gxGathers/index?id=${item.id}`)" v-for="(item, index) in majorList" :key="index"
          class="academy_item">
          <div class="academy_item_logo">
            <img :src="item.schoolBadge" alt="">
          </div>
          <div class="academy_item_detail">
            <div style="max-width: 250px;" class="school_name elp">{{ item.schoolName }}</div>
            <div style="max-width: 250px;" class="school_major elp"><span>开设专业：</span>{{ item.majorName }}</div>
            <div class="school_msg">
              <div>招生简章</div>
              <div>常见问题</div>
              <div>报考咨询</div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="news_box">
      <div class="news_til">
        <img style="width: 36px;margin-right: 6px;" src="~@/assets/img/TechnicalCollege/25.png" alt=""> 热门简章
      </div>
      <div class="news_concent">
        <div class="news_concents">
          <div @click="goNext(`/gxGathers/school/enrollmentGuide?enrollId=${item.id}&id=${item.schoolId}`)"
            v-for="(item, index) in newsList" :key="index">
            <div></div>
            <div class="elp">{{ item.name }}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  props: {
    whichSchoolId: {
      type: String,
    },
    schoolName: {
      type: String,
    },

  },
  data() {
    return {
      whichNews: 1,
      sImg: '',
      link: '',
      majorList: [],
      newsList: [],
      search: {
        pageSize: 999,
        pageNum: 1,
        projectTypeId: '',
        projectCategoryId: '',
      },
    };
  },
  created() {
    this.projectType = localStorage.getItem('projectType');
    this.search.projectTypeId = localStorage.getItem('projectTypeId');
    this.search.projectCategoryId = localStorage.getItem('projectCategoryId');

    this.getStudyAbroadSchoolPage()

  },
  mounted() {

  },
  methods: {
    chooseMajor(i) {
      this.whichNews = i
    },
    getStudyAbroadSchoolPage() {

      know.selectGraduateSchoolPage({
        ...this.search
      })
        .then((res) => {
          this.majorList = res.rows
        });
      know.selectBriefsPcGraduatePage({
        ...this.search
      })
        .then((res) => {
          this.newsList = res.rows
        });
    },

    goPointLink() {
      if (this.link) {
        window.open(this.link, '_blank');

      }
    }

  },
};
</script>

<style lang="less" scoped>
.mainboxs {
  width: 1200px;
  margin: 0 auto;
  background: #F5F7F9;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.mainbox {
  width: 890px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  padding: 18px;

  .news_til {
    display: flex;
    justify-content: space-between;

    .news_til_left {
      display: flex;
      align-items: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
      font-weight: bold;
      font-size: 22px;
      color: #444444;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .news_til_right {
      display: flex;
      align-items: center;
      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #777777;
      cursor: pointer;

      img {
        width: 12px;
        height: 12px;
        margin-left: 10px;
      }
    }

  }

  .academy_list {
    padding: 30px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0;

    .academy_item {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      cursor: pointer;

      .academy_item_logo {
        width: 95px;
        height: 95px;
        border-radius: 0px 0px 0px 0px;


      }

      .academy_item_detail {
        margin-left: 26px;

        .school_name {
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }

        .school_major {
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 12px;
          color: #333333;
          margin-top: 12px;

          span {

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 14px;
            color: #888888;
          }
        }

        .school_msg {
          display: flex;
          justify-content: space-between;

          >div {
            width: 74px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 12px;
            margin-right: 10px;
            margin-top: 10px;
          }

          >div:nth-child(1) {
            background: url('~@/assets/img/TechnicalCollege/53.png');
            background-size: 100% 100%;
            color: #3D6CC8;

          }

          >div:nth-child(2) {
            background: url('~@/assets/img/TechnicalCollege/54.png');
            background-size: 100% 100%;
            color: #DF932F;

          }

          >div:nth-child(3) {
            background: url('~@/assets/img/TechnicalCollege/55.png');
            background-size: 100% 100%;
            color: #BC3535;

          }
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 40px;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid #3D6CC8;
        cursor: pointer;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 14px;
        color: #3D6CC8;
        margin-left: 50px;

        &:hover {
          background: #3D6CC8;
          color: #FFFFFF;
        }
      }

      .line {
        width: 0px;
        height: 64px;
        border: 1px solid #D6D5E6;
        margin-left: 31px;
      }

      &:nth-child(2n) {
        margin-left: 30px;
      }

      &:hover {
        .school_name {
          color: #3D6CC8;

        }
      }
    }
  }



}

.news_box {
  width: 290px;
  height: 396px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px;

  .news_til {
    display: flex;
    align-items: center;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
    font-weight: normal;
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    flex-shrink: 0;

    .news_til_left_eng {
      margin-left: 5px;
      width: 100%;
      // height: 26px;
      line-height: 24px;
      font-family: Helvetica, Helvetica;
      font-weight: normal;
      font-size: 13px;
      color: rgba(54, 72, 122, 0.5);
      border-bottom: 1px solid rgba(154, 163, 188, 0.5);
    }


  }

  .news_concent {
    width: 240px;
    height: 300px;
    border-radius: 4px 4px 4px 4px;
    margin-top: 13px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .news_concents {
      padding: 15px 0;

      >div {
        display: flex;
        align-items: center;

        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 14px;
        color: #444444;
        margin-bottom: 10px;
        cursor: pointer;

        >div:nth-child(1) {
          width: 3px;
          height: 3px;
          background: #E1E1E1;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 0;
        }

        >div:nth-child(2) {
          width: 240px;
        }
      }

      >div:hover {
        color: #BD1212;
      }
    }

    .news_concents_bot {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #1061FF;
      cursor: pointer;
      width: 244px;
      height: 30px;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #3D6CC8;
      margin: 0 auto;

      &:hover {
        background: rgba(61, 108, 200, 0.1);
      }
    }
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>